

@import url('https://rsms.me/inter/inter.css');

html, body { font-family: 'Inter', sans-serif; background-color: '#F5F5F5';
  width: 100vw;
  overflow-x: hidden;
}
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

h1 {
  padding-left: 10px;
  font-size: 26px;
  font-weight: 700;
  color :#656565;
}